import React from "react";
import DesarrolloMvpSection from "../../components/Projects/desarrolloMvp/desarrolloMvp.js";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import { graphql } from "gatsby";

const desarrolloMvpPage = () => (    
    <Layout>
        <SEO title="Codeicus | DesarrolloMvp" />
        <DesarrolloMvpSection/>
    </Layout>
)

export default desarrolloMvpPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;